import './Simpozium.css'
import InformationBoard from "../InformationBoard/InformationBoard";

import image1 from '../../img/portfolio/Simpozium/IMG1.webp'
import image2 from '../../img/portfolio/Simpozium/IMG2.webm'
import image3 from '../../img/portfolio/Simpozium/IMG3.webp'
import image4 from '../../img/portfolio/Simpozium/IMG4.webp'


export default function Simpozium(){
    return(
        <div>
            <InformationBoard/>
            <div className='simpozium_contaner'>
                <div className='grid-container'>
                    <div class="item1">
                        <p className='simpozium_title'>Аватар</p>
                    </div>
                    <div class="item2"><img className='img_style' src={image1} loading="lazy"></img></div>
                    <div class="item3">
                        <video preload='auto'
                                className="img_style" 
                                controls loading="lazy"
                                autoPlay={true}
                                loop={true}
                                muted
                                playsInline>
                            <source src={image2} type="video/webm" />
                            Ваш браузер не поддерживает видео.
                        </video>
                    </div>    

                    <div class="item4"><img className='img_style' src={image3} loading="lazy"></img></div>
                    <div class="item5"><img className='img_style' src={image4} loading="lazy"></img></div>
                </div>
            </div>
        </div>
    )
}