import './Footer.css';
import magnumLogo from '../../img/M-letter.png';
import footer_decor from '../../img/decor-footer.png';
import UpButton from '../UpButton/UpButton';
import vk from '../../img/vk.svg'
import tg from '../../img/tg.svg'
import rutube from '../../img/rutube.svg'
import behance from '../../img/behance.svg'
import youtube from '../../img/youtube.svg'

function Footer() {
  return (
    <div className="footer">
      {/* <img src={footer_decor} className='footer__decor' alt='decoration'/> */}
      <div className='footer__container'>
        <img className='footer__img-logo' src={magnumLogo} alt='Magnum'/>
        <div className='footer__contacts'>
            <p className='footer__info  text-medium'>КОНТАКТЫ</p>
            <p className='footer__info  text-medium'><a className='footer__tel' href='tel:+74954927062'>8 495 492 70 62</a></p>
            <p className='footer__info text-medium'>MOSCOW</p>
        </div>
        <div className='footer_sosial'>
          <nav className='nav_bar'>
            <a href='https://www.behance.net/MagnumDigitalStudio' className="social_media" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 11">
                <path d="M4.654 3c.461 0 .887.035 1.278.14.39.07.711.216.996.391s.497.426.641.747c.14.32.216.711.216 1.137 0 .496-.106.922-.356 1.242-.215.32-.566.606-.997.817.606.176 1.067.496 1.348.922s.461.957.461 1.563c0 .496-.105.922-.285 1.278a2.3 2.3 0 0 1-.782.887c-.32.215-.711.39-1.137.496a5.3 5.3 0 0 1-1.278.176L0 12.803V3zm-.285 3.978c.39 0 .71-.105.957-.285.246-.18.355-.497.355-.887 0-.216-.035-.426-.105-.567a1 1 0 0 0-.32-.355 1.8 1.8 0 0 0-.461-.176c-.176-.035-.356-.035-.567-.035H2.17v2.31c0-.005 2.2-.005 2.2-.005zm.105 4.193c.215 0 .426-.035.606-.07.176-.035.356-.106.496-.216s.25-.215.356-.39c.07-.176.14-.391.14-.641 0-.496-.14-.852-.426-1.102-.285-.215-.676-.32-1.137-.32H2.17v2.734h2.305zm6.858-.035q.428.427 1.278.426c.39 0 .746-.106 1.032-.286q.426-.32.53-.64h1.74c-.286.851-.712 1.457-1.278 1.848-.566.355-1.243.566-2.06.566a4.1 4.1 0 0 1-1.527-.285 2.8 2.8 0 0 1-1.137-.782 2.85 2.85 0 0 1-.712-1.172c-.175-.461-.25-.957-.25-1.528 0-.531.07-1.032.25-1.493.18-.46.426-.852.747-1.207.32-.32.711-.606 1.137-.782a4 4 0 0 1 1.493-.285c.606 0 1.137.105 1.598.355.46.25.817.532 1.102.958.285.39.496.851.641 1.348.07.496.105.996.07 1.563h-5.15c0 .58.21 1.11.496 1.396m2.24-3.732c-.25-.25-.642-.391-1.103-.391-.32 0-.566.07-.781.176s-.356.25-.496.39a.96.96 0 0 0-.25.497c-.036.175-.07.32-.07.46h3.196c-.07-.526-.25-.882-.497-1.132zm-3.127-3.728h3.978v.957h-3.978z"/>
            </svg>
            </a>
            <a href='https://rutube.ru/channel/38321418/videos/' className="social_media" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 75 50">
                <path d="M58.7126 15.7047C61.7077 15.7047 64.1357 13.1964 64.1357 10.1023C64.1357 7.00825 61.7077 4.5 58.7126 4.5C55.7175 4.5 53.2895 7.00825 53.2895 10.1023C53.2895 13.1964 55.7175 15.7047 58.7126 15.7047Z"/>
                <path d="M42.8959 15.1864H4.78125V58.9579H15.3898V44.7174H35.7176L44.9922 58.9579H56.8712L46.6438 44.6517C49.82 44.1268 52.1069 42.8799 53.5045 40.9112C54.902 38.9424 55.6007 35.7924 55.6007 31.5926V28.3113C55.6007 25.8176 55.3466 23.8489 54.902 22.3394C54.4573 20.8301 53.6951 19.5177 52.6152 18.3364C51.4717 17.2208 50.2012 16.4333 48.6767 15.9083C47.1521 15.4489 45.2463 15.1864 42.8959 15.1864ZM41.1808 35.0706H15.3898V24.8332H41.1808C42.6418 24.8332 43.6581 25.0957 44.1664 25.5551C44.6746 26.0144 44.9922 26.8675 44.9922 28.1144V31.7894C44.9922 33.1019 44.6746 33.955 44.1664 34.4143C43.6581 34.8737 42.6418 35.0706 41.1808 35.0706Z"/>  
              </svg>
            </a>
            <a href="https://www.youtube.com/@magnumdigitalstudio" className="social_media" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26 16">
              <path 
                d="M23.498 6.186a2.854 2.854 0 0 0-2.006-2.017C19.846 4 12 4 12 4s-7.846 0-9.492.169a2.854 2.854 0 0 0-2.006 2.017A29.835 29.835 0 0 0 0 12a29.835 29.835 0 0 0 .502 5.814 2.854 2.854 0 0 0 2.006 2.017C4.154 20 12 20 12 20s7.846 0 9.492-.169a2.854 2.854 0 0 0 2.006-2.017A29.835 29.835 0 0 0 24 12a29.835 29.835 0 0 0-.502-5.814zM9.75 15.525V8.475L15.5 12l-5.75 3.525z"
              />
              </svg>
            </a>
            <a href='https://t.me/magnumdigitalstudio' className="social_media" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg"   viewBox="-10 0 56 21">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M18.0161 0.0673828C8.09369 0.0673828 0.0498047 8.11127 0.0498047 18.0337C0.0498047 27.9562 8.09369 36 18.0161 36C27.9386 36 35.9825 27.9562 35.9825 18.0337C35.9825 8.11127 27.9386 0.0673828 18.0161 0.0673828ZM26.3439 12.2859C26.0733 15.1268 24.9037 22.0208 24.3083 25.203C24.0564 26.549 23.5605 27.0007 23.0805 27.0449C22.037 27.1408 21.245 26.3554 20.2343 25.6928C18.6532 24.6565 17.7599 24.0111 16.2249 22.9996C14.4509 21.8307 15.6011 21.1882 16.6119 20.1383C16.8763 19.8634 21.4736 15.6823 21.5623 15.3028C21.5735 15.2554 21.5839 15.0786 21.4786 14.9852C21.3737 14.8918 21.2188 14.9237 21.1067 14.9493C20.9482 14.9852 18.4222 16.655 13.5285 19.9583C12.8113 20.4505 12.162 20.6902 11.5802 20.678C10.9385 20.664 9.70455 20.3151 8.78719 20.0168C7.66214 19.651 6.76777 19.4577 6.84575 18.8368C6.88635 18.513 7.33192 18.1821 8.18244 17.844C13.42 15.5623 16.9123 14.0578 18.66 13.3308C23.6493 11.2557 24.6863 10.895 25.3618 10.8831C25.5106 10.8806 25.843 10.9176 26.0582 11.0922C26.2397 11.2396 26.29 11.439 26.3137 11.5788C26.3378 11.7185 26.3676 12.0369 26.3439 12.2859Z"/>
            </svg>
            </a>
            <a href="https://vk.com/magnundigitalstudio" className="social_media" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -15 90 90"> 
              <path d="M 87.935 21.894 c 0.626 -2.086 0 -3.619 -2.977 -3.619 h -9.846 c -2.504 0 -3.658 1.324 -4.283 2.785 c 0 0 -5.008 12.204 -12.101 20.132 c -2.294 2.295 -3.337 3.026 -4.59 3.026 c -0.625 0 -1.531 -0.731 -1.531 -2.816 V 21.894 c 0 -2.503 -0.727 -3.619 -2.813 -3.619 H 34.32 c -1.564 0 -2.506 1.162 -2.506 2.264 c 0 2.373 3.547 2.921 3.913 9.597 v 14.499 c 0 3.179 -0.574 3.757 -1.826 3.757 c -3.337 0 -11.457 -12.26 -16.273 -26.288 c -0.944 -2.727 -1.89 -3.828 -4.406 -3.828 H 3.376 C 0.562 18.275 0 19.599 0 21.059 c 0 2.608 3.337 15.543 15.542 32.65 c 8.136 11.682 19.599 18.016 30.031 18.016 c 6.258 0 7.033 -1.407 7.033 -3.83 v -8.829 c 0 -2.814 0.593 -3.375 2.575 -3.375 c 1.46 0 3.963 0.729 9.805 6.362 c 6.676 6.676 7.776 9.671 11.532 9.671 h 9.846 c 2.812 0 4.219 -1.407 3.408 -4.182 c -0.889 -2.767 -4.076 -6.781 -8.305 -11.538 c -2.295 -2.712 -5.738 -5.633 -6.781 -7.094 c -1.461 -1.877 -1.043 -2.711 0 -4.381 C 74.687 44.53 86.684 27.631 87.935 21.894"></path>
              </svg>
            </a>
          </nav>
        </div>
      </div>
      <UpButton/>
    </div>
  );
}

export default Footer;
