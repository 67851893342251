import './Nornickel.css'

import image1 from '../../img/Nornickel/IMG1.webp'
import image2 from '../../img/Nornickel/IMG2.webp'


export default function Nornickel(){
    return(
        <div className='container_Nornickel'>
            <p className='nornickel__text nornickel_title'>backstage</p>
            <div className='nornickel__image-container'>
                <img className="nornickel__image" src={image2} loading="lazy"/>
                <img className="nornickel__image" src={image1} loading="lazy"/>
            </div>
        </div>
    )
}