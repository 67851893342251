import './Ambi.css';

import image1 from '../../img/portfolio/Ambi/IMG1.webp';
import image2 from '../../img/portfolio/Ambi/IMG2_gif.webm';
import image3 from '../../img/portfolio/Ambi/IMG3.webp';
import image4 from '../../img/portfolio/Ambi/IMG4.webp';
import image1_Block2 from '../../img/portfolio/Ambi/IMG1_Block2.webp';
import image2_Block2 from '../../img/portfolio/Ambi/IMG2_Block2.webp';
import image3_Block2 from '../../img/portfolio/Ambi/IMG3_Block2.webp';
import image4_Block2 from '../../img/portfolio/Ambi/IMG4_Block2.webp';
import InformationBoard from "../InformationBoard/InformationBoard";

export default function Ambi(){
    return(
        <div>
            <InformationBoard/>
            <div className='container_Comedy'>
                <p className='comedy__text comedy_title'>Разработка игры</p>
                <div className='comedy__image-container'>
                    <img className="comedy__image2" src={image1} loading="lazy"/>
                    <video preload='auto'
                              className="comedy__image2" 
                              controls loading="lazy"
                              autoPlay={true}
                              loop={true}
                              muted
                              playsInline>
                          <source src={image2} type="video/webm" />
                          Ваш браузер не поддерживает видео.
                    </video>
                    <img className="comedy__image2" src={image3} loading="lazy"/>
                    <img className="comedy__image2" src={image4} loading="lazy"/>
                </div>
            </div>
            <div className='container_Comedy'>
                <p className='comedy__text comedy_title'>Разработка UI/UX</p>
                <div className='comedy__image-container'>
                    <img className="comedy__image2" src={image1_Block2} loading="lazy"/>
                    <img className="comedy__image2" src={image2_Block2} loading="lazy"/>
                    <img className="comedy__image2" src={image3_Block2} loading="lazy"/>
                    <img className="comedy__image2" src={image4_Block2} loading="lazy"/>
                </div>
            </div>
        </div>
    )
}