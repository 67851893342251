import './InformationBoard.css'


import { useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';


export default function InformationBoard(){
    const path = useLocation();
    const [location, setLocation] = useState('');
    const [portfolioList, setPortfolioList] = useState(null);
  
    useEffect(() => {
      window.scrollTo(0, 0);
      const locationPath = path.pathname.split('/')[2];
      setLocation(locationPath);

      let area;
      switch (locationPath) {
        case 'forum-edo': 
          area = 'forum-edo';
          break;  
        case 'comedy': 
          area = 'comedy';
          break; 
        case 'udachnyye-pesni': 
          area = 'udachnyye-pesni';
          break;   
        case 'zhkkh':
            area = 'zhkkh';
            break;
        case 'simpozium':
            area = 'simpozium';
            break;          
        case 'ambi':
            area = 'ambi';
            break;          
        default:
          area = null;
      }
  
      if (area) {
        setPortfolioList(contentText[area][0]);
      }
    
      console.log(locationPath)
    }, [path.pathname]);
    return(
        <div className='edo_block'>
                <div className='edo_container'>
                    <div className='edo_container_left'>
                        <div className='edo_item'>
                            <p className="edo_text edo_title_green">{portfolioList?.block1Title}</p>
                            <p className='edo_text edo__about-statistic'>{portfolioList?.block1Text}</p>
                        </div>
                        <div className='edo_item'>
                            <p className="edo_text edo_title_green">{portfolioList?.block2Title}</p>
                            <p className='edo_text edo__about-statistic'>{portfolioList?.block2Text}</p>
                        </div>
                    </div> 
                    <div className='edo_container_right'>
                        <div className='edo_item2'>
                            <p className="edo_text edo_title_green">{portfolioList?.block3Title}</p>
                            <p className='edo_text edo__about-statistic'>{portfolioList?.block3Text}</p>
                        </div>
                        <div className='edo_item2'>
                            <p className="edo_text edo_title_green">{portfolioList?.block4Title}</p>
                            <p className='edo_text edo__about-statistic'>{portfolioList?.block4Text}</p>
                        </div>
                    </div>
                </div>
        </div>
    )
}

const forum_edoBlock1Title = 'Брендинг';
const forum_edoBlock1Text = 'Разработка фирменного стиля мероприятия и подготовка к анимации.';
const forum_edoBlock2Title = 'Motion';
const forum_edoBlock2Text = 'Разработка графического пакета анимационных роликов для форума';
const forum_edoBlock3Title = 'CGI opener';
const forum_edoBlock3Text = 'Фирменный открывающий ролик для меропрития';
const forum_edoBlock4Title = 'Сопровождение';
const forum_edoBlock4Text = 'Выезд на мероприятие и контроль запуска контента';

const comedyBlock1Title = 'CGI';
const comedyBlock1Text = 'Разработка уникальных композиций на арт-объект в виде Елки';
const comedyBlock2Title = 'Motion';
const comedyBlock2Text = 'Разработка графического пакета анимационных роликов для декораций в фойе';
const comedyBlock3Title = 'Graphic';
const comedyBlock3Text = 'Разработка баннера под запрос клиента, подготовка к печати и монтаж на площадке.';
const comedyBlock4Title = 'Сопровождение';
const comedyBlock4Text = 'Выезд на мероприятие и контроль запуска контента';

const udachnyye_pesniBlock1Title = 'CGI';
const udachnyye_pesniBlock1Text = 'Разработка 3D контента под артиста';
const udachnyye_pesniBlock2Title = 'Motion';
const udachnyye_pesniBlock2Text = 'Разработка графического пакета анимационных роликов для мероприятия';
const udachnyye_pesniBlock3Title = 'Graphic';
const udachnyye_pesniBlock3Text = 'Разрабокта стилизованного контента с помощью иллюстраций';
const udachnyye_pesniBlock4Title = 'Сопровождение';
const udachnyye_pesniBlock4Text = 'Выезд на мероприятие и контроль запуска контента';

const zhkkhBlock1Title = 'CGI';
const zhkkhBlock1Text = 'Разработка 3D-контента (ЖКХ) под артиста';
const zhkkhBlock2Title = 'Motion';
const zhkkhBlock2Text = 'Разработка графического пакета анимационных роликов для мероприятия';
const zhkkhBlock3Title = 'Edit video';
const zhkkhBlock3Text = 'Монтаж роликов под задачи сценария мероприятия';
const zhkkhBlock4Title = 'Сопровождение';
const zhkkhBlock4Text = 'Выезд на мероприятие и контроль запуска контента';

const simpoziumBlock1Title = 'CGI';
const simpoziumBlock1Text = 'Фирменный открывающий ролик для мероприятия';
const simpoziumBlock2Title = 'Motion';
const simpoziumBlock2Text = 'Разработка графического пакета анимационных роликов для форума';
const simpoziumBlock3Title = 'Аватар';
const simpoziumBlock3Text = 'Разработка уникального digital-ведущего, который в лайф формате коммуницировал с участниками';
const simpoziumBlock4Title = 'Сопровождение';
const simpoziumBlock4Text = 'Выезд на мероприятие и контроль запуска контента';

const ambiBlock1Title = 'САЙТ';
const ambiBlock1Text = 'Разработка структуры и дизайна многостраничного сайта парков развлечений';
const ambiBlock2Title = 'Motion';
const ambiBlock2Text = 'Разработка графического пакета анимационных роликов для экранов в парках';
const ambiBlock3Title = 'Игра в роблокс';
const ambiBlock3Text = 'Разработка плейса и механик, реализация на платформе Roblox.';
const ambiBlock4Title = 'ui / 2D';
const ambiBlock4Text = 'Разработка интерфеса в рамках брендбука, верстка полиграфии и брендирование поверхностей внутри игры.';



const contentText = {
    'forum-edo': [{
        block1Title: forum_edoBlock1Title,
        block1Text: forum_edoBlock1Text,
        block2Title: forum_edoBlock2Title,
        block2Text: forum_edoBlock2Text,
        block3Title: forum_edoBlock3Title,
        block3Text: forum_edoBlock3Text,
        block4Title: forum_edoBlock4Title,
        block4Text: forum_edoBlock4Text,
    }],
    'comedy':[{
        block1Title: comedyBlock1Title,
        block1Text: comedyBlock1Text,
        block2Title: comedyBlock2Title,
        block2Text: comedyBlock2Text,
        block3Title: comedyBlock3Title,
        block3Text: comedyBlock3Text,
        block4Title: comedyBlock4Title,
        block4Text: comedyBlock4Text,
    }],
    'udachnyye-pesni':[{
        block1Title: udachnyye_pesniBlock1Title,
        block1Text: udachnyye_pesniBlock1Text,
        block2Title: udachnyye_pesniBlock2Title,
        block2Text: udachnyye_pesniBlock2Text,
        block3Title: udachnyye_pesniBlock3Title,
        block3Text: udachnyye_pesniBlock3Text,
        block4Title: udachnyye_pesniBlock4Title,
        block4Text: udachnyye_pesniBlock4Text,
    }],
    'zhkkh':[{
        block1Title: zhkkhBlock1Title,
        block1Text: zhkkhBlock1Text,
        block2Title: zhkkhBlock2Title,
        block2Text: zhkkhBlock2Text,
        block3Title: zhkkhBlock3Title,
        block3Text: zhkkhBlock3Text,
        block4Title: zhkkhBlock4Title,
        block4Text: zhkkhBlock4Text,
    }],
    'simpozium':[{
        block1Title: simpoziumBlock1Title,
        block1Text: simpoziumBlock1Text,
        block2Title: simpoziumBlock2Title,
        block2Text: simpoziumBlock2Text,
        block3Title: simpoziumBlock3Title,
        block3Text: simpoziumBlock3Text,
        block4Title: simpoziumBlock4Title,
        block4Text: simpoziumBlock4Text,
    }], 
    'ambi':[{ 
        block1Title: ambiBlock1Title,
        block1Text: ambiBlock1Text,
        block2Title: ambiBlock2Title,
        block2Text: ambiBlock2Text,
        block3Title: ambiBlock3Title,
        block3Text: ambiBlock3Text,
        block4Title: ambiBlock4Title,
        block4Text: ambiBlock4Text,
    }]
}