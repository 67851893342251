import './Roblox.css'
import ContactForm from '../blocks/ContactForm/ContactForm';
import Footer from '../blocks/Footer/Footer';
import Header from '../blocks/Header/Header';
import Recomend from '../blocks/Recomend/Recomend';
import {Helmet} from "react-helmet";
import page from '../img/PieChart.webp'
import Star from '../img/star.webp'
import Highres from '../img/Highres.webp'
import Fasade from '../img/Fasade.webp'
import Maincraft from '../img/Maincraft.webp'  
import Super from '../img/Super.webp' 
import pyaterka from '../img/pyaterka.webp' 
import Robl from '../img/Robl.webp'
import circle from '../img/circle.png'
import Star_green from '../img/star_green.webp'
import magnumTitle from '../img/magnum-logo.png'
import Cat from '../img/Cat.webp'
import Spline from '@splinetool/react-spline';
import Apmet from '../img/Apmet.webp'
import MainPic from '../img/Roblox_ad.jpg'
const image_blocks = 'SplineModels/metaverse_plashka.splinecode'



function Roblox(){
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Создание метавселенных на заказ — Magnum Digital Studio</title>
            <meta name="description" content="Проектируем метавселенные для брендов, HR, концерты, с геймдизайном и 3D-графикой. Погрузите пользователей в цифровую реальность вместе с Magnum Digital Studio." />
        
            <link rel="canonical" href="https://magnum.design/metaverse" />
        </Helmet>
        <div id="bg-wrap" style={{position: 'fixed', width: '100%', height: '100%', zIndex: -1}}>
            <div className="animated-background">
            <div className="gradient1"></div>
            <div className="gradient2"></div>
            </div>
        </div>
        <div className="services-expand">
            <Header/>
            <div className='metaverse_continer' > 
                <div className='metaverseVideo'>
                    <img className='metaverseVideo__image' src={MainPic}></img>
                    <div className='metaverseVideo__overlay'>
                        <div className='metaverseVideo__subtitle'>
                            <h1 className='metaverse__text metaverse__about-title'>Разработка<br></br>метавселенной в Roblox<br></br>
                            для компаний и брендов</h1>
                            <p className='metaverse__text metaverse__about-text'
                            >Создаем увлекательные виртуальные миры для<br></br>эффективного PR и рекламы среди подростковой<br></br>аудитории и их родителей.</p>
                        </div>
                    </div>
                   
                </div>
            </div>
            <div className='container_block'>
                <p className='metaverse__text metaverse_title'>Охваты аудитории в roblox</p>
                <div className='statistic_container'>
                    <div className='statistic_container_left'>
                        <div className='statistic_item statistic_size_one'>
                            <p className="metaverse__text metaverse_title_green">{'> 2 млн'}</p>
                            <p className='metaverse__text metaverse__about-statistic'>Мировая посещаемость</p>
                        </div>
                        <div className='statistic_item statistic_size_one'>
                            <p className="metaverse__text metaverse_title_green">{'> 10 млн'}</p>
                            <p className='metaverse__text metaverse__about-statistic'>Аудитория России в Roblox в месяц</p>
                        </div>
                        <div className='statistic_item statistic_size_two'>
                            <p className="metaverse__text metaverse_title_green">{'> 2,5 часов'}</p>
                            <p className='metaverse__text metaverse__about-statistic'>Среднее время в игре</p>
                        </div>
                    </div>    
                    <div className='statistic_container_right'>
                        <div className='statistic_item statistic_size_tree'>
                            <img src={page} alt="Охваты аудитории в роблокс"  className='page_style'/>
                            <img src={Star} alt="Звезда" className='border_image'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container_block'>
                <p className='metaverse__text metaverse_title'>Создавайте метавселенные<br/> и привлекайте целевой трафик<br/> для бренда!</p>
                <div className='thesis_conteiner'>
                    <img className='thesis_border_image' src={circle}/>
                    <div className='thesis_inner_block'>
                        <img src={Highres} alt="" className='back_img'/>
                        <img src={Star} alt="Звезда" className='star_img'/>
                        <p className='metaverse__text metaverse__about'>Максимальная вовлеченность аудитории через<br /> геймификацию и иммерсивный опыт</p>
                    </div>
                    <div className='thesis_inner_block'>
                        <img src={Fasade} alt="" className='back_img'/>
                        <img src={Star} alt="Звезда" className='star_img'/>
                        <p className='metaverse__text metaverse__about'>Социальное взаимодействие и вирусный эффект</p>
                    </div>
                    <div className='thesis_inner_block'>
                        <img src={Maincraft} alt="" className='back_img'/>
                        <img src={Star} alt="Звезда" className='star_img'/>
                        <p className='metaverse__text metaverse__about'>Создавая спецпроект в метавселенной, он останется<br /> с вами навсегда</p>
                    </div>
                    <div className='thesis_inner_block'>
                        <img src={Super} alt="" className='back_img'/>
                        <img src={Star} alt="Звезда" className='star_img'/>
                        <p className='metaverse__text metaverse__about'>Трансформация бренда через новый эффективный<br /> канал с огромным трафиком</p>
                    </div>
                    <div className='thesis_inner_block'>
                        <img src={pyaterka} alt="" className='back_img'/>
                        <img src={Star} alt="Звезда" className='star_img'/>
                        <p className='metaverse__text metaverse__about'>Персонализация и кастомизация метавселенной под<br /> интересы ЦА</p>
                    </div>
                    <div className='thesis_inner_block'>
                        <img src={Robl} alt="" className='back_img'/>
                        <img src={Star} alt="Звезда" className='star_img'/>
                        <p className='metaverse__text metaverse__about'>Безопасносный и контролируемый контент. Мы<br /> следим за модерацией контента и соблюдаем все<br /> правила платформы Roblox.</p>
                    </div>
                </div>
            </div>
            <div className='container_block'>
                <div className='statistic_item border'>
                    <div className='marcketing__items'>
                        <div className='marcketing__item1'>
                            <p className='metaverse__text metaverse_title'>Безграничные возможности<br /> для маркетинга</p>
                        </div>
                        <div className='marcketing__item'>
                            <img src={Star_green} alt="" className='green_star'/>
                            <p className='metaverse__text marcketing_text'>Полностью измеримая эффективность спецпроекта</p>
                        </div>
                        <div className='marcketing__item'>
                            <img src={Star_green} alt="" className='green_star'/>
                            <p className='metaverse__text marcketing_text'>Промо-кампании</p>
                        </div>
                        <div className='marcketing__item'>
                            <img src={Star_green} alt="" className='green_star'/>
                            <p className='metaverse__text marcketing_text'>Внедрение промокодов в рамках кампании позволит вывести посещения в прямые продажи</p></div>
                        <div className='marcketing__item'>
                            <img src={Star_green} alt="" className='green_star'/>
                            <p className='metaverse__text marcketing_text'>Вы можете измерить количество посещений, уникальных пользователей, время в игре</p>
                        </div>
                        <div className='marcketing__item'>
                            <img src={Star_green} alt="" className='green_star'/>
                            <p className='metaverse__text marcketing_text'>Возможность создать уникальный мир, который останется с вами навсегда</p></div>
                        <div className='marcketing__item'>
                            <img src={Star_green} alt="" className='green_star'/>
                            <p className='metaverse__text marcketing_text'>Метавселенную можно дорабатывать и реактировать под события и презентации</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contact_form' > 
                <div className='contact_form_text_block'>
                    <p className='metaverse__text metaverse__about-title'>Готовы создать<br /> революционный<br /> проект в<br /> метавселенной?</p>
                    <p className='metaverse__text metaverse__about-text'>Создаем увлекательные виртуальные миры для<br></br>эффективного PR и рекламы среди подростковой<br></br>аудитории и их родителей.</p>
                    {/*<a  href="http://localhost:3000/#contacts" className="contact-form__buttons">ОСТАВИТЬ ЗАЯВКУ</a>*/}
                </div>
            </div>
            <div className='container_block'>
                <div className='statistic_item border up'>
                    <p className='metaverse__about-title'>О нас</p>
                    <div className='about__items'>
                        <img loading="lazy" className='magnum_logo-img' src={magnumTitle} alt='magnum digital studio'/>
                        <div className='about__item'>
                            <img src={Star} alt="" className='white_star'/>
                            <p className='about_title'>Одни из первопроходцев<br /> индустрии метавселенных в РФ</p>
                            <p className='about_text'>Мы создали первый мир в 2020 году</p>
                        </div>
                        <div className='about__item'>
                            <img src={Star} alt="" className='white_star'/>
                            <p className='about_title'>Мы выросли из event-сферы</p>
                            <p className='about_text'>В наш мир захочется возвращаться<br /> и захочется рассказать о нем!</p>
                        </div>
                        <div className='about__item'>
                            <img src={Star} alt="" className='white_star'/>
                            <p className='about_title'>Собственная студия<br /> CGI и Motion-дизайнеров</p>
                            <p className='about_text'>Весь контент создается внутри студии и мы снижаем издержки, не привлекая аутсорс</p>
                         </div>   
                        <div className='about__item'>
                            <img src={Star} alt="" className='white_star'/>
                            <p className='about_title'>Опыт в геймдеве</p>
                            <p className='about_text'>Гарантируем интересные механики<br /> и подходим к созданию метамиров<br /> креативно</p>
                        </div>
                        <div className='about__item'>
                            <img src={Star} alt="" className='white_star'/>
                            <p className='about_title'>Метавселенные-это про бизнес!</p>
                            <p className='about_text'>Мы несем ответственность за охваты, строго соблюдаем дедлайны</p>
                        </div>    
                        <div className='about__item'>
                            <img src={Star} alt="" className='white_star'/>
                            <p className='about_title'>Гибкость в работе</p>
                            <p className='about_text'>Всегда прислушиваемся к заказчику и готовы менять детали проекта, если в процессе появятся новые вводные</p>
                        </div>
                        <div className='about__item marcketing__apmet'>
                            <img src={Apmet} alt="" className='apmet'/>
                            <p className='apmet_text indent'>Участники Ассоциации<br/> рынка метавселенных</p>
                        </div>
                        <div className='about__item'>
                            
                        </div>
                      
                    </div>
                    
                </div>
            </div>
            <div className="services-exand__servises-list">
                <Spline scene={image_blocks} />
            </div>
            <ContactForm id="contact-form-section"/>
            <Footer/>
        </div>
        </>   
    )
}

export default Roblox;