import './Recomend.css'

import { useNavigate } from 'react-router-dom';

import area1_img from '../../img/portfolio/pred_portfolio.webp'
import area2_img from '../../img/portfolio/tomsk.webp'
import area3_img from '../../img/portfolio/alphabank.webp'
import area4_img from '../../img/portfolio/iri.webp'
import area5_img from '../../img/portfolio/MTC.webp'
import area6_img from '../../img/portfolio/zolotye_hity.webp'
import area7_img from '../../img/portfolio/urban_forum.webp'


import area1_img_2 from '../../img/gamzatov_portfolio.webp'
import area2_img_2 from '../../img/100_creative.webp'
import area3_img_2 from '../../img/basis_portfolio.webp'
import area4_img_2 from '../../img/pyaterochka_portfolio.webp'
import area5_img_2 from '../../img/den_znaniy_portfolio.webp'
import area6_img_2 from '../../img/supercity-portfolio.webp'
import area7_img_2 from '../../img/ostrovok_portfolio.webp'


import area1_img_3 from '../../img/UdachPesnyPlitka.webp'
import area2_img_3 from '../../img/Simposium.webp'
import area3_img_3 from '../../img/Comedy.webp'
import area4_img_3 from '../../img/Nornickel.webp'
import area5_img_3 from '../../img/EDO.webp'
import area6_img_3 from '../../img/zhkh.webp'
import area7_img_3 from '../../img/AmbiLand.webp'


const imageURLs = {
    'den-predprinimatelya': [area1_img,  'ДЕНЬ ПРЕДПРИНИМАТЕЛЯ', 'Правительство Москвы.'], 
    'brainstorm': [area2_img,  'ROBLOX BRAIN STORM', '#game #modeling #university'],
    'alfa-digital': [area3_img,  'ALFA DIGITAL', 'Онлайн конференция Alfa Bank'],
    'digital-berozy': [area4_img,  'ART PERFOMANCE ИРИ', '#3D #motion #concert'],
    'mts-cyber-sation': [area5_img,  'MTC CYBERSATION', '#online #conf #CGI'],
    'zolotyye-khity': [area6_img,  'ЗОЛОТЫЕ ХИТЫ МУЗ-ТВ', '#stage #motion #avatar'],
    'moscow-urban-forum': [area7_img,  'AR MUF', '#forum #modeling #Moscow'],
    'nornickel': [area4_img_3, 'NORNICKEL DIGITAL WEEK', '#виртуальная студия #эфирная графика #3D'],
    'forum-edo':[area5_img_3, 'ФОРУМ ЭДО', '#форум #эфирная графика #motion'], 
    'comedy': [area3_img_3, 'COMEDY', '#motion #art #christmas'], 
  }
  
  const imageURLs2 = {
    'sto-let-rasul-gamzatov': [area1_img_2,  '100 ЛЕТ. РАССУЛ ГАМЗАТОВ', '#концерт #контент'], 
    'most-creative-awards': [area2_img_2,  'MOST CREATIVE AWARDS', '#conf #awards #content'],
    'basis-devops-conf': [area3_img_2, 'BASIS DEVOPS CONF', '#expo #content #AR #gaming #bot'],
    'pyaterochka-roblox': [area4_img_2, 'ПЯТЕРОЧКА ROBLOX', '#metaverse #game design #modelling'],
    'den-znaniy-v-kremle': [area5_img_2, 'ДЕНЬ ЗНАНИЙ В КРЕМЛЕ', '#концерт #эфир #телеканал Россия'],
    'super-city-roblox': [area6_img_2, 'SUPER CITY ROBLOX', '#metaverse #game design #modeling #3D'],
    'ostrovok-high-load': [area7_img_2, 'OSTROVOK&HI-LOAD', '#expo #content  #gaming #App'], 
    'udachnyye-pesni': [area1_img_3, 'КОНЦЕРТ\n «УДАЧНЫЕ ПЕСНИ»', '#cg #3D modeling #motion'],
    'simpozium': [area2_img_3, 'СИМПОЗИУМ\n «СОЗДАВАЯ БУДУЩЕЕ»', '#конференция #аватар #motion'],
    'zhkkh': [area6_img_3, 'ЖКХ', '#motion #art #концерт'],
    'ambi': [area7_img_3, "AMBI.LAND\n ПАРКИ РАЗВЛЕЧЕНИЙ", '#roblox #motion #разработка сайта']
  }


  const getRandomCases = (numCases, arrData) => {
    const casesArray = Object.entries(arrData);
    const selectedCases = [];
    while (selectedCases.length < numCases && casesArray.length > 0) {
        const randomIndex = Math.floor(Math.random() * casesArray.length);
        selectedCases.push(casesArray.splice(randomIndex, 1)[0]);
    }
    return selectedCases;
};

function Recomend() {
    const navigate = useNavigate();
    const randomCasesLeftBlock = getRandomCases(1, imageURLs);
    const randomCasesRightBlock = getRandomCases(1, imageURLs2);
    const handleClick = (area) => {

        navigate(`/portfolio/${area}`);
      }
    
    return (
        <div id='portfolio' className='recomend'>
            <p className='recomend__title text-big'>ПОСЛЕДНИЕ КЕЙСЫ</p>
                <div className='recomend__container'>
                {randomCasesLeftBlock.map(([area, imageUrl]) => { 
                return(
                    
                        <div className='recomend__item recomend__first' style={{ backgroundImage: `url(${imageUrl[0]})` }} onClick={() =>
                            handleClick(area)}>
                        <div className='recomend__overlay'>
                            <p className='recoment__text' >{imageUrl[1]}. <br/> {imageUrl[2]} </p>        
                        </div>
                        </div>
                    )   
                })}
                {randomCasesRightBlock.map(([area, imageUrl]) => { 
                return(
                    
                        <div className='recomend__item recomend__first' style={{ backgroundImage: `url(${imageUrl[0]})` }} onClick={() =>
                            handleClick(area)}>
                        <div className='recomend__overlay'>
                            <p className='recoment__text' >{imageUrl[1]}.<br/> {imageUrl[2]} </p>        
                        </div>
                        </div>
                    )   
                })}
                </div>
            
                 
                
        </div>  
        );      
}

export default Recomend;