import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Portfolio.css';
import { useNavigate } from 'react-router-dom';
import ImageWithVideoOnHover from '../VideoBlock/VideoBlock';
import area1_img from '../../img/portfolio/pred_portfolio.webp'
import area2_img from '../../img/portfolio/tomsk.webp'
import area3_img from '../../img/portfolio/alphabank.webp'
import area4_img from '../../img/portfolio/iri.webp'
import area5_img from '../../img/portfolio/MTC.webp'
import area6_img from '../../img/portfolio/zolotye_hity.webp'
import area7_img from '../../img/portfolio/urban_forum.webp'

import videoUrl1 from '../../img/den_predprinemately.webm' 
import videoUrl2 from '../../img/tomsk_1.webm'
import videoUrl3 from '../../img/alphaban_vertical.webm'
import videoUrl4 from '../../img/iri-big.webm'
import videoUrl5 from '../../img/MTS shot 7.webm'
import videoUrl6 from '../../img/zolotye hity_small.webm'
import videoUrl7 from '../../img/ar_vertical.webm'
import videoUrl8 from '../../img/Nornickel_gif.webm' 
import videoUrl9 from '../../img/navedenie.webm'
// import mainImgTomsk from '../../videos/tomsk.mp4'
// import aboutImgTomsk from '../../img/projects/tomsk/img4.png'
// import img1 from '../../img/roblox_tomsk2.gif'
// import img2 from '../../img/projects/tomsk/img2.png'
// import img3 from '../../img/projects/tomsk/img1.png'
// import img4 from '../../img/roblox_tomsk.gif'

// import mainIriImg from '../../img/projects/ИРИ/image 13.png'

// // видео для портфолио называются как картинка но на самом деле это ВИДЕО
// import mainAlpha  from '../../videos/alifa.mp4'
// import mainIri  from '../../videos/iri_20s.mp4'
// import mainMts  from '../../videos/mts 20 sec.mp4'
// import mainHity  from '../../videos/zolotye hity 20s.mp4'
// import mainUrban  from '../../videos/AR20 sec.mp4'
// import mainPred  from '../../videos/den predprinemately.mp4'

// import mainGamzatov from '../../videos/big-videos-portfolio/гамзатов.mp4'
// import mainCreative100 from '../../videos/big-videos-portfolio/100креативных.mp4'
// import mainZnanyi from '../../videos/big-videos-portfolio/деньзнаний.mp4'
// import mainPyaterochka from '../../videos/big-videos-portfolio/пятерочка.mp4'
// import mainBasis from '../../videos/big-videos-portfolio/базис.mp4'
// import mainOstrovok from '../../videos/big-videos-portfolio/осторовок.mp4'
// import mainSuperCity from '../../videos/big-videos-portfolio/супер сити.mp4'

// // картинки под проект
// import aboutPred from '../../img/portfolio-about-small/predprinimatel.png'
// import aboutAlpha from '../../img/portfolio-about-small/alpha.png'
// import aboutIri from '../../img/portfolio-about-small/iri.png'
// import aboutMts from '../../img/portfolio-about-small/mts.png'
// import aboutHity from '../../img/portfolio-about-small/muz.png'
// import aboutUrban from '../../img/portfolio-about-small/urban.png'

// import aboutGamzatov from '../../img/portfolio-about-small/gamzatov.png'
// import aboutCreative100 from '../../img/portfolio-about-small/100-creative.png'
// import aboutBasis from '../../img/portfolio-about-small/basis.png'
// import aboutPyaterochka from '../../img/portfolio-about-small/pyaterochka.png'
// import aboutDenZnaniy from '../../img/portfolio-about-small/den-znaniy.png'
// import aboutSuperCity from '../../img/portfolio-about-small/super-city.png'
// import aboutOstrovok from '../../img/portfolio-about-small/ostrovok.png'

// // раскадровка проекта
// import alphaFrame1 from '../../img/alphaFrame1.png'
// import alphaFrame2 from '../../img/alphaFrame2.png'
// import alphaFrame3 from '../../img/alphaFrame3.png'
// import alphaFrame4 from '../../img/alphaFrame4.png'

// import predFrame1 from '../../img/0408.jpg'
// import predFrame2 from '../../img/ezgif.com-video-to-gif (7).gif'
// import predFrame3 from '../../img/ezgif.com-video-to-gif (4).gif'
// import predFrame4 from '../../img/pred_portfolio.jpg'

// import urbanFrame1 from '../../img/urbanFrame1.JPG'
// import urbanFrame2 from '../../img/urbanFrame2.gif'
// import urbanFrame3 from '../../img/urbanFrame3.png'
// import urbanFrame4 from '../../img/urbanFrame4.png'

// import hityFrame1 from '../../img/frameHity1.png'
// import hityFrame2 from '../../img/frameHity2.png'
// import hityFrame3 from '../../img/frameHity3.png'
// import hityFrame4 from '../../img/frameHity4.png'

// import iriFrame1 from '../../img/iriFrame1.png'
// import iriFrame2 from '../../img/iriFrame2.png'
// import iriFrame3 from '../../img/iriFrame3.png'
// import iriFrame4 from '../../img/iriFrame4.png'

// import mtsFrame from '../../img/mtsFrame.png'

// // section 2 portfolio


import area1_img_2 from '../../img/gamzatov_portfolio.webp'
import area2_img_2 from '../../img/100_creative.webp'
import area3_img_2 from '../../img/basis_portfolio.webp'
import area4_img_2 from '../../img/pyaterochka_portfolio.webp'
import area5_img_2 from '../../img/den_znaniy_portfolio.webp'
import area6_img_2 from '../../img/supercity-portfolio.webp'
import area7_img_2 from '../../img/ostrovok_portfolio.webp'

import area8_img_2 from '../../img/UdachPesnyPlitka.webp'
import area9_img_2 from '../../img/Simposium.webp'
import area10_img_2 from '../../img/Comedy.webp'
import area11_img_2 from '../../img/Nornickel.webp'
import area12_img_2 from '../../img/EDO.webp'
import area13_img_2 from '../../img/zhkh.webp'
import area14_img_2 from  '../../img/AmbiLand.webp'


const imageURLs = {
  'den-predprinimatelya': [area1_img, videoUrl1, 'ДЕНЬ ПРЕДПРИНИМАТЕЛЯ', '#concert #opening #motion'], 
  'brainstorm': [area2_img, videoUrl2, 'ROBLOX BRAIN STORM', '#game #modeling #university'],
  'alfa-digital': [area3_img, videoUrl3, 'ALFA DIGITAL', '#conf #motion #3D'],
  'digital-berozy': [area4_img, videoUrl4, 'ART PERFOMANCE ИРИ', '#3D #motion #concert'],
  'mts-cyber-sation': [area5_img, videoUrl5, 'MTC CYBERSATION', '#online #conf #CGI'],
  'zolotyye-khity': [area6_img, videoUrl6, 'ЗОЛОТЫЕ ХИТЫ МУЗ-ТВ', '#stage #motion #avatar'],
  'moscow-urban-forum': [area7_img, videoUrl7, 'AR MUF', '#forum #modeling #Moscow']
}

const imageURLs2 = {
  'sto-let-rasul-gamzatov': [area1_img_2, area1_img_2, '100 ЛЕТ. РАССУЛ ГАМЗАТОВ', '#концерт #контент'], 
  'most-creative-awards': [area2_img_2, area2_img_2, 'MOST CREATIVE AWARDS', '#conf #awards #content'],
  'basis-devops-conf': [area3_img_2, area3_img_2, 'BASIS DEVOPS CONF', '#expo #content #AR #gaming #bot'],
  'pyaterochka-roblox': [area4_img_2, area4_img_2, 'ПЯТЕРОЧКА ROBLOX', '#metaverse #game design #modelling'],
  'den-znaniy-v-kremle': [area5_img_2, area5_img_2, 'ДЕНЬ ЗНАНИЙ В КРЕМЛЕ', '#концерт #эфир #телеканал Россия'],
  'super-city-roblox': [area6_img_2, area6_img_2, 'SUPER CITY ROBLOX', '#metaverse #game design #modeling #3D'],
  'ostrovok-high-load': [area7_img_2, area7_img_2, 'OSTROVOK&HI-LOAD', '#expo #content  #gaming #App']
}

const imageURLs3 = {
  'udachnyye-pesni': [area8_img_2, area8_img_2, 'КОНЦЕРТ\n «УДАЧНЫЕ ПЕСНИ»', '#cg #3d modeling #motion'],
  'simpozium': [area9_img_2, area9_img_2, 'СИМПОЗИУМ\n «СОЗДАВАЯ БУДУЩЕЕ»', '#конференция #аватар #motion'],
  'comedy': [area10_img_2, area10_img_2, 'COMEDY', '#motion #art #christmas'],
  'nornickel': [area11_img_2, videoUrl8, 'NORNICKEL DIGITAL WEEK', '#виртуальная студия #эфирная графика #3d'],
  'forum-edo': [area12_img_2, area12_img_2, 'ФОРУМ ЭДО', '#форум #эфирная графика #motion'],
  'ambi': [area14_img_2, area14_img_2, "AMBI.LAND\n ПАРКИ РАЗВЛЕЧЕНИЙ", '#roblox #motion #разработка сайта'],
  'zhkkh': [area13_img_2, videoUrl9, 'ЖКХ', '#motion #art #концерт'],
}

function Portfolio() {
  const navigate = useNavigate();
  

  const handleClick = (area) => {
    // const imageUrl = imageURLs[area];
    // const props = { ...imageUrl[4] };
    
    // Object.keys(props).forEach(key => {
    //   if (typeof props[key] === 'string' && !props[key].startsWith('http')) {
    //     props[key] = process.env.PUBLIC_URL + props[key];
    //   } else if (Array.isArray(props[key])) {
    //     props[key] = props[key].map(img => img.startsWith('http') ? img : process.env.PUBLIC_URL + img);
    //   }
    // });
    
    // localStorage.setItem('clickedImageProps', JSON.stringify(props));
    navigate(`/portfolio/${area}`);
  }
  
  const handleClick2 = (area) => {
    // const imageUrl = imageURLs2[area];
    // const props = { ...imageUrl[4] };
    
    // Object.keys(props).forEach(key => {
    //   if (typeof props[key] === 'string' && !props[key].startsWith('http')) {
    //     props[key] = process.env.PUBLIC_URL + props[key];
    //   } else if (Array.isArray(props[key])) {
    //     props[key] = props[key].map(img => img.startsWith('http') ? img : process.env.PUBLIC_URL + img);
    //   }
    // });
    
    // localStorage.setItem('clickedImageProps', JSON.stringify(props));
    navigate(`/portfolio/${area}`);
  }
  
  return (
    <div className="portfolio">
      <div className="container3">
        {Object.entries(imageURLs3).map(([area, imageUrl]) => {
          return(<div  key={area} className={`portfolio__item ${area} area`} onClick={() => handleClick2(area)}>
            <ImageWithVideoOnHover imageUrl={imageUrl[0]} videoUrl={imageUrl[1]} title={imageUrl[2]} subtitle={imageUrl[3]} />
          </div>)
        }
      )}
      </div>
      <div className="container2">
        {Object.entries(imageURLs2).map(([area, imageUrl]) => {
          return(<div  key={area} className={`portfolio__item ${area} area`} onClick={() => handleClick2(area)}>
            <ImageWithVideoOnHover imageUrl={imageUrl[0]} videoUrl={imageUrl[1]} title={imageUrl[2]} subtitle={imageUrl[3]} />
          </div>)
        }
      )}
      </div>
      <div className="container">
        {Object.entries(imageURLs).map(([area, imageUrl]) => {
          return(<div  key={area} className={`portfolio__item ${area} area`} onClick={() => handleClick(area)}>
            <ImageWithVideoOnHover imageUrl={imageUrl[0]} videoUrl={imageUrl[1]} title={imageUrl[2]} subtitle={imageUrl[3]} />
          </div>)
        }
      )}
      </div>
      
      
    </div>
  );
}

export default Portfolio;

