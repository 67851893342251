import './Comedy.css'

import image1 from '../../img/portfolio/Comedy/IMG1.webp'
import image2 from '../../img/portfolio/Comedy/IMG2.webp'
import image3 from '../../img/portfolio/Comedy/IMG3.webp'
import image4 from '../../img/portfolio/Comedy/Frame6_gif.webm'
import image5 from '../../img/portfolio/Comedy/Frame7_gif.webm'
import image6 from '../../img/portfolio/Comedy/IMG4.webp'

export default function Comedy(){
    return(
        <div>
            <div className='container_Comedy'>
                <p className='comedy__text comedy_title'>Оформление банера</p>
                <div className='comedy__image-container'>
                    <img className="comedy__image" src={image1} loading="lazy"/>
                    <img className="comedy__image" src={image2} loading="lazy"/>
                </div>
            </div>
            <div className='container_Comedy'>
                <p className='comedy__text comedy_title'>Разработка контнета</p>
                <div className='comedy__image-container'>
                    <img className="comedy__image2" src={image3} loading="lazy"/>
                    <video preload='auto'
                              className="comedy__image2" 
                              controls loading="lazy"
                              autoPlay={true}
                              loop={true}
                              muted
                              playsInline>
                          <source src={image4} type="video/webm" />
                          Ваш браузер не поддерживает видео.
                    </video>
                    <video preload='auto'
                              className="comedy__image2" 
                              controls loading="lazy"
                              autoPlay={true}
                              loop={true}
                              muted
                              playsInline>
                          <source src={image5} type="video/webm" />
                          Ваш браузер не поддерживает видео.
                    </video>
                    <img className="comedy__image2" src={image6} loading="lazy"/>
                </div>
            </div>
        </div>
    )
}