import '../Comedy/Comedy.css'

import image1 from '../../img/portfolio/Zhkkh/IMG_gif.webm'
import image2 from '../../img/portfolio/Zhkkh/IMG2_gif.webm'
import image3 from '../../img/portfolio/Zhkkh/IMG3_gif.webm'
import image4 from '../../img/portfolio/Zhkkh/IMG4_gif.webm'
import image5 from '../../img/portfolio/Zhkkh/IMG5_gif.webm'
import image6 from '../../img/portfolio/Zhkkh/IMG6_gif.webp'


export default function Zhkkh(){
    return(
        <div>
            <div className='container_Comedy'>
                <p className='comedy__text comedy_title'>Разработка контенета</p>
                <div className='comedy__image-container'>
                    <video preload='auto'
                              className="comedy__image2" 
                              controls loading="lazy"
                              autoPlay={true}
                              loop={true}
                              muted
                              playsInline>
                          <source src={image1} type="video/webm" />
                          Ваш браузер не поддерживает видео.
                    </video>

                    <video preload='auto'
                              className="comedy__image2" 
                              controls loading="lazy"
                              autoPlay={true}
                              loop={true}
                              muted
                              playsInline>
                          <source src={image2} type="video/webm" />
                          Ваш браузер не поддерживает видео.
                    </video>

                    <video preload='auto'
                              className="comedy__image2" 
                              controls loading="lazy"
                              autoPlay={true}
                              loop={true}
                              muted
                              playsInline>
                          <source src={image3} type="video/webm" />
                          Ваш браузер не поддерживает видео.
                    </video>

                    <video preload='auto'
                              className="comedy__image2" 
                              controls loading="lazy"
                              autoPlay={true}
                              loop={true}
                              muted
                              playsInline>
                          <source src={image4} type="video/webm" />
                          Ваш браузер не поддерживает видео.
                    </video>

                    <video preload='auto'
                              className="comedy__image2" 
                              controls loading="lazy"
                              autoPlay={true}
                              loop={true}
                              muted
                              playsInline>
                          <source src={image5} type="video/webm" />
                          Ваш браузер не поддерживает видео.
                    </video>

                    <img className="comedy__image2" src={image6} loading="lazy"/>
                </div>
        </div>
    </div>
    )
}