import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage';
import PortfolioPage from './PortfolioPage';
import Project from './blocks/Project/Project';
import ServicesExpand from '../src/blocks/ServicesExpand/ServicesExpand';
import { useEffect } from 'react';
import Privacy from './blocks/Privacy/Privacy';
import NotFound from './NotFound';
import Roblox from './Metaverse/Roblox';

function App() {
  useEffect(() => {
    function removeElementsByClass(root, className) {
      const elements = root.querySelectorAll('.' + className);
      elements.forEach(element => {
        element.remove();
      });
    }

    removeElementsByClass(document, 'media-controls-container');

    const possibleShadowHosts = document.querySelectorAll('*');
    possibleShadowHosts.forEach(host => {
      if (host.shadowRoot) {
        removeElementsByClass(host.shadowRoot, 'media-controls-container');
      }
    });
  }, [])
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/portfolio/:area" element={<Project />} />

          <Route path="/add" element={<ServicesExpand />} />
          <Route path="/motion" element={<ServicesExpand />} />
          <Route path="/avatars" element={<ServicesExpand />} />
          
          <Route path="/ar-xr-vr" element={<ServicesExpand />} />
          <Route path="/ai" element={<ServicesExpand />} />
          {/*<Route path="/services/roblox" element={<ServicesExpand />}/>*/}
          <Route path="/metaverse" element={<Roblox />} />
          <Route path="/privacy" element={<Privacy/>} />

          <Route path="*" element={<NotFound />} />
          <Route path="not-found" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;